<template>
  <div>
    <el-form
      ref="formRef"
      :model="form"
      :rules="rules"
      inline
      label-width="80px"
      size="small"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="履约仓库" prop="warehouseCode">
            <el-select v-model="form.warehouseCode" filterable multiple>
              <el-option
                v-for="(item, index) in warehouseOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="备货渠道" prop="chanelIdList">
            <el-select
              v-model="form.chanelIdList"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) in channelOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="类目" prop="categoryId">
            <el-select
              v-model="form.categoryId"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) in categoryOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="品牌" prop="brandName">
            <el-select
              v-model="form.brandName"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) in brandOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="Style" prop="styleId">
            <el-select
              v-model="form.styleId"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="(item, index) in styleOptions"
                :key="index"
                :label="item.value"
                :value="item.key"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="SKU">
            <el-input
              v-model.trim="form.searchContentSingle"
              class="input-with-select"
              clearable
              placeholder="请输入"
              @focus="batchSearchVisible = false"
            >
              <template #append>
                <el-popover
                  v-model="batchSearchVisible"
                  placement="bottom"
                  trigger="manual"
                  width="300"
                >
                  <div style="text-align: right">
                    <div>
                      <el-input
                        v-model="form.searchContentMany"
                        type="textarea"
                        :rows="6"
                        placeholder="精确搜索，一行一项，最多支持200行"
                        @input="handleSkuInput"
                      />
                    </div>
                    <el-button
                      class="mt-2"
                      :loading="queryLoading"
                      @click="form.searchContentMany = ''"
                    >清空</el-button>
                    <el-button
                      class="mt-2"
                      :loading="queryLoading"
                      @click="batchSearchVisible = !batchSearchVisible"
                    >取消</el-button>
                    <el-button
                      class="mt-2"
                      type="primary"
                      :loading="queryLoading"
                      @click="handleBatchSearch"
                    >查询</el-button>
                  </div>
                  <el-button
                    slot="reference"
                    icon="el-icon-more"
                    @click="handleOpenBatchSearch"
                  />
                </el-popover>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label=" ">
            <el-button
              :loading="queryLoading"
              type="primary"
              @click="handleSingleSearch"
            >
              {{ $t("page.search") }}
            </el-button>
            <el-button @click="handleReset">
              {{ $t("page.reset") }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button
          type="primary"
          @click="handleExportInventory"
        >导出可用库存</el-button>
        <el-button
          type="primary"
          @click="handleBatchImportInventory"
        >批量导入</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column :title="$t('page.No')" type="seq" width="80" />
      <vxe-column field="warehouseCode" title="履约仓库代码" width="120" />
      <vxe-column field="warehouseName" title="履约仓库名称" width="120" />
      <vxe-column field="channelName" title="备货渠道" width="120" />
      <vxe-column field="sku" title="SKU" width="120" />
      <vxe-column field="categoryName" title="类目" width="120" />
      <vxe-column field="brandName" title="品牌" width="120" />
      <vxe-column field="styleName" title="Style" width="120" />
      <vxe-column field="colorName" title="Color" width="120" />
      <vxe-column field="sizeName" title="Size" width="120" />
      <vxe-column field="availableStock" width="120">
        <template #header>
          可用库存
          <el-popover placement="top-start" trigger="hover">
            从IMS系统同步的可用库存数量
            <span slot="reference" class="el-icon-question ml-1" />
          </el-popover>
        </template>
      </vxe-column>
      <vxe-column field="shareStock" width="120">
        <template #header>
          共享库存
          <el-popover placement="top-start" trigger="hover">
            其他渠道共享给本渠道的库存数量
            <span slot="reference" class="el-icon-question ml-1" />
          </el-popover>
        </template>
        <template #default="{ row }">
          <el-popover
            v-if="row.shareStock > 0"
            placement="top"
            trigger="hover"
            title="共享库存组成"
          >
            <vxe-grid
              v-bind="sharedInventoryOption"
              :data="row.shareShareList"
            />
            <template #reference>
              <span class="text-blue">{{ row.shareStock }}</span>
            </template>
          </el-popover>
        </template>
      </vxe-column>
      <vxe-column field="sellStock" width="130">
        <template #header>
          可售库存
          <el-popover placement="top-start" trigger="hover">
            <span>若本渠道库存未共享给其他渠道时,可售库存=可用库存+共享库存</span><br>
            <span>若本渠道库存已共享给其他渠道,可售库存=渠道保底数量+共享库存;</span>
            <span slot="reference" class="el-icon-question ml-1" />
          </el-popover>
        </template>
      </vxe-column>
      <vxe-column field="reserveStock" width="160">
        <template #header>
          渠道保底数量
          <el-popover placement="top-start" trigger="hover">
            预留给本渠道销售的库存
            <span slot="reference" class="el-icon-question ml-1" />
          </el-popover>
          <el-popover
            v-model="reserveStockVisible"
            placement="top"
            title="批量设置保底数量"
            trigger="manual"
          >
            <!-- 批量设置 -->
            <batch-set-quantity-form
              :query-params="queryParams"
              :table-list="tableData"
              @refresh="handleQuery"
              @close="reserveStockVisible = false"
            />
            <span
              slot="reference"
              class="el-icon-edit-outline ml-1"
              @click="reserveStockVisible = !reserveStockVisible"
            />
          </el-popover>
        </template>
      </vxe-column>
      <vxe-column field="toShareStock" width="120">
        <template #header>
          总共享数量
          <el-popover
            placement="top-start"
            trigger="hover"
            content="总共享库存=可用库存-渠道保底数量"
          >
            <span slot="reference" class="el-icon-question ml-1" />
          </el-popover>
        </template>
        <template #default="{ row }">
          <el-popover
            v-if="row.toShareStock > 0"
            placement="top"
            trigger="hover"
            title="共享给其他渠道的库存情况"
          >
            <vxe-grid v-bind="totalShareOptions" :data="row.toShareList" />
            <template #reference>
              <span class="text-blue">{{ row.toShareStock }}</span>
            </template>
          </el-popover>
        </template>
      </vxe-column>
      <vxe-column :title="$t('page.operate')" fixed="right" width="120">
        <template #default="{ row }">
          <el-button
            size="small"
            type="text"
            @click="handleSetSharing(row)"
          >设置共享</el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <!-- 库存共享设置 -->
    <set-sharing-dialog
      :visible.sync="setSharingVisible"
      :current-row="currentRow"
      @refresh="handleQuery"
    />
    <!-- 批量导入 -->
    <batch-import
      :visible.sync="batchImportInventoryVisible"
      :options="getBatchImportInventoryOptions"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import Paging from '@/components/Paging/index.vue'
import BatchImport from '@/components/BatchImport/index.vue'
import BatchSetQuantityForm from './components/BatchSetQuantityForm.vue'
import SetSharingDialog from './components/SetSharingDialog.vue'
import { debounceGetTableMaxHeight } from '@/utils'
import {
  SharedInventoryOption,
  TotalShareOptions,
  ReserveStockOptions,
  BatchImportInventoryOptions
} from './const/index'
import {
  getShareInventoryPage,
  getShareInventorySelect,
  importShareConfigExcel,
  importShareConfigExcelConfig
} from '@/api/inventory-management'
import { unifiedExport } from '@/api/a-base-host'
export default {
  name: 'ChannelInventoryShared',
  components: {
    Paging,
    SetSharingDialog,
    BatchSetQuantityForm,
    BatchImport
  },
  data() {
    return {
      form: {
        sku: '',
        warehouseCode: [],
        chanelIdList: [],
        categoryId: [],
        brandName: [],
        styleId: [],
        // 关于sku搜索
        searchContentSingle: '',
        searchContentMany: ''
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      maxHeight: 0,
      channelOptions: [],
      warehouseOptions: [],
      chanelOptions: [],
      categoryOptions: [],
      brandOptions: [],
      styleOptions: [],
      setSharingVisible: false,
      reserveStockVisible: false,
      queryLoading: false,
      tableData: [],
      currentRow: null,
      rules: Object.freeze({
        warehouseCode: [
          { required: true, message: '请选择仓库', trigger: 'change' }
        ]
        // styleId: [
        //   { required: true, message: '请选择Style', trigger: 'change' }
        // ]
      }),
      // 关于sku搜索
      maxLines: 200,
      batchSearchVisible: false,
      batchImportInventoryVisible: false,
      isSingleSearch: true
    }
  },
  computed: {
    getBatchImportInventoryOptions() {
      return {
        importExcel: importShareConfigExcel,
        sureImportExcel: importShareConfigExcelConfig,
        // 模版下载链接
        templateUrl: '',
        ...BatchImportInventoryOptions
      }
    },
    reserveStockOptions() {
      return ReserveStockOptions
    },
    sharedInventoryOption() {
      return SharedInventoryOption
    },
    totalShareOptions() {
      return TotalShareOptions
    },
    queryParams() {
      const { searchContentSingle, searchContentMany, ...rest } = this.form
      // sku
      const sku = this.isSingleSearch
        ? searchContentSingle
          ? [searchContentSingle]
          : []
        : this.preText(searchContentMany)

      return {
        ...rest,
        sku,
        current: this.pager.current,
        size: this.pager.size
      }
    }
  },
  watch: {
    // 'form.warehouseCode'(wareCode) {
    //   this.chanelOptions = []
    //   // 渠道
    //   this.getChannel(wareCode)
    // }
  },
  created() {
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  async mounted() {
    // 类目
    this.getCategory()
    // 品牌
    this.getBrand()
    // 渠道
    this.getChannel()
    // 履约仓库 、 Style
    await Promise.all([this.getWarehouse(), this.getStyle()])
    this.handleQuery()
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    handleSetSharing(row) {
      this.currentRow = row
      this.setSharingVisible = true
    },
    handleCancelReserveStock() {
      this.$refs.reserveStockRef.doClose()
    },
    handleReset() {
      const warehouseItem = this.warehouseOptions[0]?.key
      this.$refs.formRef.resetFields()
      this.form.warehouseCode = warehouseItem ? [warehouseItem] : []
      this.form.styleId = [this.styleOptions[0]?.key]
      // 关于sku
      this.form.sku = []
      this.form.searchContentMany = ''
      this.form.searchContentSingle = ''
      this.isSingleSearch = true
      this.handleQuery()
    },
    handleBatchSearch() {
      this.isSingleSearch = false
      this.handleQuery()
    },
    handleSingleSearch() {
      this.isSingleSearch = true
      this.handleQuery()
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    handleQuery() {
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          this.queryLoading = true
          if (this.controller) {
            this.controller.abort()
            setTimeout(() => {
              this.queryLoading = true
            })
          }
          this.controller = new AbortController()
          const signal = this.controller.signal
          if (this.queryChange) {
            this.pager.current = 1
          }
          const { datas } = await getShareInventoryPage(
            this.queryParams,
            signal
          ).finally(() => {
            this.queryLoading = this.queryChange = false
          })
          this.tableData = datas?.records || []
          this.pager.total = datas?.pager.total || 0
          this.queryLoading = false
        }
      })
    },
    handleSkuInput(value) {
      const text = value || ''
      const lines = this.preText(text)
      if (lines.length > this.maxLines) {
        this.form.searchContentMany = lines.slice(0, this.maxLines).join('\n')
        this.$message.warning(`最多输入${this.maxLines}行`)
      }
    },
    handleOpenBatchSearch() {
      this.batchSearchVisible = !this.batchSearchVisible
      // 如果打开了则将页码设置为1
      if (this.batchSearchVisible) {
        this.pager.current = 1
      }
    },
    async handleExportInventory() {
      console.log('handleExportInventory', this.queryParams)

      const params = {
        appName: 'oms',
        exportType: 'stock-share-config',
        reqParam: JSON.stringify({
          ...this.queryParams
        })
      }

      const { success } = await unifiedExport(params).finally(() => {
        this.exportLoading = false
      })

      if (success) {
        const downloadUrl = `${process.env['VUE_APP_OMS_API']}async-export/export-list`
        this.$message.success({
          dangerouslyUseHTMLString: true,
          message: `<div>导出成功，请前往<span style="color: #409eff;cursor: pointer" onclick="window.open('${downloadUrl}')">下载中心</span>查看</div>`
        })
      } else {
        this.$message.error('导出失败')
      }
    },
    handleBatchImportInventory() {
      this.batchImportInventoryVisible = true
    },
    async getStyle() {
      const ret = await this.getShareInventorySelect({
        type: 4,
        key: 'styleOptions'
      })
      const styleItem = ret[0]?.key
      // 初始值
      this.form.styleId = styleItem ? [styleItem] : []
    },
    async getWarehouse() {
      const ret = await this.getShareInventorySelect({
        type: 0,
        key: 'warehouseOptions'
      })
      const warehouseItem = ret[0]?.key
      // 初始值
      this.form.warehouseCode = warehouseItem ? [warehouseItem] : []
    },
    async getBrand() {
      this.getShareInventorySelect({
        type: 3,
        key: 'brandOptions'
      })
    },
    async getCategory() {
      this.getShareInventorySelect({
        type: 2,
        key: 'categoryOptions'
      })
    },
    async getChannel() {
      this.getShareInventorySelect({
        type: 5,
        key: 'channelOptions'
      })
    },
    async getShareInventorySelect({ type, key, wareCode }) {
      try {
        // 下拉值选项 0履约仓库  1备货渠道   2 类目  3 品牌 4 Style 5 所有渠道
        const { datas } = await getShareInventorySelect({ type, wareCode })
        this[key] = datas || []
        return Promise.resolve(datas)
      } catch (error) {
        console.log(error)
      }
    },
    preText(pretext) {
      if (Array.isArray(pretext)) return pretext

      const array = (pretext || '')
        .replace(/\n/g, ',')
        .split(',')
        .filter((item) => item)
        .map((item) => item.replace(/\s/g, ''))

      return array
    }
  }
}
</script>
<style scoped>
.text-blue {
  color: #427cff;
  cursor: pointer;
}
</style>
