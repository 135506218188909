<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="库存共享设置"
    :visible.sync="visible"
    center
    inline
    top="5vh"
    width="800px"
  >
    <el-form ref="formRef" :model="form" :rules="rules" label-width="110px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="仓库" prop="warehouseName">
            <el-input v-model="form.warehouseName" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备货渠道" prop="channelName">
            <el-input v-model="form.channelName" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="SKU" prop="sku">
            <el-input v-model="form.sku" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="可用库存" prop="availableStock">
            <el-input v-model="form.availableStock" type="number" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="渠道保底数量" prop="reserveStock">
            <el-input-number
              v-model="form.reserveStock"
              :controls="false"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总共享数量" prop="toShareStock">
            <el-input v-model="toShareStock" type="number" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label=" ">
            <vxe-toolbar>
              <template #buttons>
                <el-button type="primary" @click="handleAddChannel">
                  添加共享渠道
                </el-button>
              </template>
            </vxe-toolbar>
            <div
              v-if="detailQueries.length > 0"
              style="overflow: auto; max-height: 320px; padding: 10px 0"
            >
              <div
                v-for="(item, i) in detailQueries"
                :key="i"
                class="mb-2"
                style="display: flex; align-items: center"
              >
                <el-select
                  v-model="item.channelId"
                  placeholder="请选择渠道"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(c, index) in channelOptions"
                    :key="index"
                    :label="c.value"
                    :value="c.key"
                  />
                </el-select>
                <el-input-number
                  v-model="item.percentage"
                  class="ml-2 w-200px"
                  placeholder="请输入"
                  :controls="false"
                  :min="0"
                  :max="100"
                  @change="handleChangePercentage(i)"
                />
                <span class="ml-2">%</span>
                <el-input
                  v-model="item.sharesNumber"
                  disabled
                  class="ml-2 w-200px"
                />
                <el-button
                  type="text"
                  @click="handleDeleteChannel(i)"
                ><span
                  class="el-icon-delete ml-3"
                /></el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div style="text-align: right">
        <el-button
          :loading="submitLoading"
          @click="handleClose"
        >取消</el-button>
        <el-button
          type="primary"
          :loading="submitLoading"
          @click="handleConfirm"
        >确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  setShareInventoryConfig,
  getShareInventorySelect
} from '@/api/inventory-management'

import _ from 'lodash'
export default {
  name: 'SetSharing',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentRow: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        warehouseName: '',
        channelName: '',
        sku: '',
        availableStock: 0,
        reserveStock: undefined
      },
      detailQueries: [],
      channelList: [],
      submitLoading: false,
      rules: Object.freeze({
        reserveStock: [
          {
            required: true,
            validator: this.validateReserveStock,
            trigger: 'blur'
          }
        ]
      }),
      channelOptions: []
    }
  },
  computed: {
    toShareStock() {
      const { reserveStock, availableStock } = this.form
      const _reserveStock = _.isNumber(reserveStock) ? reserveStock : 0
      const _availableStock = _.isNumber(availableStock) ? availableStock : 0
      // 总共享数量=可用库存-渠道保底库存；
      const toShareStock = _availableStock - _reserveStock
      return toShareStock
    },
    queryParams() {
      const { channelId, warehouseCode } = this.currentRow

      const { reserveStock, sku } = this.form
      return {
        channelId,
        reserveStock,
        sku,
        detailQueries: this.detailQueries,
        wareCode: warehouseCode
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        const {
          warehouseName,
          warehouseCode,
          channelName,
          sku,
          availableStock,
          toShareList,
          reserveStock
        } = this.currentRow
        Object.assign(this.form, {
          warehouseName,
          channelName,
          sku,
          reserveStock,
          availableStock: _.isNumber(availableStock) ? availableStock : 0
        })
        // console.log('toShareList',toShareList);
        this.detailQueries = (toShareList || []).map((item) => {
          return {
            channelId: item.channelId,
            percentage: item.percentage,
            sharesNumber: item.stock
          }
        })

        this.getShareInventorySelect(warehouseCode)
      }
    }
  },
  methods: {
    handleAddChannel() {
      this.detailQueries.push({
        channelId: '',
        percentage: 100,
        sharesNumber: undefined
      })
    },
    handleDeleteChannel(i) {
      this.detailQueries.splice(i, 1)
    },
    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('update:currentRow', null)
      this.$refs.formRef.resetFields()
      this.detailQueries = []
    },
    handleChangePercentage(index) {
      const { percentage } = this.detailQueries[index]
      const _percentage = _.isNumber(percentage) ? percentage : 0
      const sharesNumber = this.toShareStock * (_percentage / 100)
      this.$set(this.detailQueries[index], 'sharesNumber', sharesNumber)
    },
    vaildSharedChannelShare() {
      const detailQueries = this.detailQueries
      const percentageTotal = detailQueries.reduce((acc, item) => {
        acc += _.isNumber(item.percentage) ? item.percentage : 0
        return acc
      }, 0)
      if (detailQueries.length > 0 && percentageTotal !== 100) {
        return true
      }
      return false
    },
    handleConfirm() {
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          try {
            const isIncomplete = this.detailQueries.some(
              (item) => !item.channelId || !item.percentage
            )

            if (isIncomplete) {
              this.$message({
                type: 'warning',
                message: '请完善共享渠道信息'
              })
              return
            }

            // if (this.vaildSharedChannelShare()) {
            //   this.$message({
            //     type: 'warning',
            //     message: '各共享渠道占比之和不等于100%，请检查分配的渠道占比'
            //   })
            //   return
            // }

            this.submitLoading = true
            const { msg, code } = await setShareInventoryConfig(
              this.queryParams
            ).finally(() => {
              this.submitLoading = false
            })
            if (code === 0) {
              this.$message({
                type: 'success',
                message: msg
              })
              this.handleClose()
              this.$emit('refresh')
            } else {
              this.$message.success(msg || '操作失败')
            }
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async getShareInventorySelect(wareCode) {
      try {
        const { datas } = await getShareInventorySelect({ type: 1, wareCode })
        this.channelOptions = datas || []
      } catch (error) {
        console.log(error)
      }
    },
    validateReserveStock(_rule, value, callback) {
      const { availableStock } = this.currentRow
      const _reserveStock = _.isNumber(value) ? value : 0
      const _availableStock = _.isNumber(availableStock) ? availableStock : 0
      if ([undefined, null, ''].includes(value)) {
        callback(new Error('请输入渠道保底数量'))
      } else if (_reserveStock > _availableStock) {
        callback(new Error('渠道保底数量不能大于等于“可用库存”数量'))
      } else {
        callback()
      }
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog--center {
  text-align: left;
}

.w-200px {
  width: 200px;
}
</style>
