// 共享库存
export const SharedInventoryOption = {
  border: true,
  resizable: true,
  showOverflow: true,
  columns: [
    { field: 'channelName', title: '备货渠道', width: 120, align: 'center' },
    { field: 'stock', title: '库存', width: 120, align: 'center' }
  ]
}

// 总共享
export const TotalShareOptions = {
  border: true,
  resizable: true,
  showOverflow: true,
  columns: [
    { field: 'channelName', title: '备货渠道', width: 120, align: 'center' },
    { field: 'stock', title: '共享库存', width: 120, align: 'center' }
  ]
}

export const ReserveStockOptions = [{ value: 0, label: '固定数量' }, { value: 1, label: '百分比' }]

export const ReserveStockEnum = {
  // 固定数量
  FIXEDQUANTITY: 0,
  // 百分比
  PERCENTAGE: 1
}

export const BatchImportInventoryOptions = {
  gridOptions: {
    columns: [
      { field: 'warehouseCode', title: '履约仓库', width: 120 },
      { field: 'channelName', title: '备货渠道', width: 120 },
      { field: 'sku', title: 'SKU', width: 120 },
      { field: 'categoryName', title: '类目', width: 120 },
      { field: 'brandName', title: '品牌', width: 120 },
      { field: 'styleName', title: 'Style', width: 120 },
      { field: 'colorName', title: 'Color', width: 120 },
      { field: 'sizeName', title: 'Size', width: 120 },
      { field: 'availableStock', title: '可用库存', width: 120 },
      // { field: 'shareStock', title: '共享库存' , width: 120 },
      { field: 'reserveStock', title: '渠道保底数量', width: 120 },
      { field: 'shareStock', title: '总共享数量', width: 120 },
      { field: 'shareChannel1', title: '*共享渠道1', width: 120 },
      { field: 'shareChannel1Rate', title: '共享比例1（%）', width: 150 },
      { field: 'shareChannel2', title: '共享渠道2', width: 120 },
      { field: 'shareChannel2Rate', title: '共享比例2（%）', width: 150 },
      { field: 'shareChannel3', title: '共享渠道3', width: 120 },
      { field: 'shareChannel3Rate', title: '共享比例3（%）', width: 150 },
      { field: 'shareChannel4', title: '共享渠道4', width: 120 },
      { field: 'shareChannel4Rate', title: '共享比例4（%）', width: 150 },
      { field: 'shareChannel5', title: '共享渠道5', width: 120 },
      { field: 'shareChannel5Rate', title: '共享比例5（%）', width: 150 },
      { field: 'shareChannel6', title: '共享渠道6', width: 120 },
      { field: 'shareChannel6Rate', title: '共享比例6（%）', width: 150 },
      { field: 'shareChannel7', title: '共享渠道7', width: 120 },
      { field: 'shareChannel7Rate', title: '共享比例7（%）', width: 150 },
      { field: 'shareChannel8', title: '共享渠道8', width: 120 },
      { field: 'shareChannel8Rate', title: '共享比例8（%）', width: 150 },
      { field: 'shareChannel9', title: '共享渠道9', width: 120 },
      { field: 'shareChannel9Rate', title: '共享比例9（%）', width: 150 },
      { field: 'shareChannel10', title: '共享渠道10', width: 120 },
      { field: 'shareChannel10Rate', title: '共享比例10（%）', width: 150 },
      { field: 'errorMsg', title: '错误信息', width: 120 }
    ]
  }
}
