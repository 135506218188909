<template>
  <el-form ref="formRef" :model="form" label-width="140px">
    <el-form-item label="按" prop="type">
      <el-select
        v-model="form.type"
        placeholder="百分比"
        @change="form.num = undefined"
      >
        <el-option
          v-for="(item, index) in reserveStockOptions"
          :key="index"
          :value="item.value"
          :label="item.label"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      :label="getTitle"
      :rules="{
        required: true,
        message: '请输入',
        trigger: 'blur',
      }"
      prop="num"
    >
      <el-input-number
        v-model="form.num"
        :min="1"
        :max="getMaxNum"
        :controls="false"
        placeholder="请输入"
      />
      <span class="ml-2">{{ isPercentage ? "%" : "个" }}</span>
    </el-form-item>
    <el-form-item style="text-align: right">
      <el-button
        type="primary"
        :loading="submitLoading"
        @click="handleConfirm"
      >确定</el-button>
      <el-button :loading="submitLoading" @click="handleClose">取消</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { setShareInventoryBatchConfig } from '@/api/inventory-management'
import { ReserveStockOptions, ReserveStockEnum } from '../const'
import _ from 'lodash'
export default {
  name: 'BatchSetQuantityForm',
  props: {
    queryParams: {
      type: Object,
      default: () => ({})
    },
    tableList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        type: 1,
        num: undefined
      },
      submitLoading: false
    }
  },
  computed: {
    getMaxNum() {
      return this.isPercentage ? 100 : 99999
    },
    getTitle() {
      return this.isPercentage ? '设置固定数量为' : '设置为可用库存的'
    },
    reserveStockOptions() {
      return ReserveStockOptions
    },
    isPercentage() {
      return this.form.type === ReserveStockEnum.PERCENTAGE
    }
  },
  methods: {
    handleClose() {
      this.$refs.formRef.resetFields()
      this.$emit('close')
    },
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          // 固定数量效验可用库存
          if (!this.tableList.length) return
          if (!this.isPercentage && !this.verifyAvailableInventory()) {
            this.$confirm(
              '当前列表中存在可用库存≤设置的渠道保底数量的数据，是否跳过并继续处理',
              '',
              {
                distinguishCancelAndClose: true,
                confirmButtonText: '确认',
                cancelButtonText: '取消'
              }
            )
              .then(() => {
                this.submit()
              })
              .catch(() => {
                console.log('cancel')
              })
          } else {
            this.submit()
          }
        }
      })
    },
    async submit() {
      try {
        const params = {
          ...this.form,
          ...this.queryParams
        }
        this.submitLoading = true
        const { code, msg } = await setShareInventoryBatchConfig(
          params
        ).finally(() => {
          this.submitLoading = false
        })

        if (code === 0) {
          this.$message.success(msg)
          this.$emit('refresh')
          this.handleClose()
        } else {
          this.$message.success(msg || '操作失败')
        }
      } catch (error) {
        console.log(error)
      }
    },
    verifyAvailableInventory() {
      // todo 可用库存会有不存在的情况？？？ 需要过滤？？？

      const tableList = this.tableList
      const num = this.form.num
      // 数据列表的所有记录中如果存在可用库存≤设置的渠道保底数量时，小弹窗提示“当前列表中存在可用库存≤设置的渠道保底数量的数据，是否跳过并继续处理”。
      const vaild = tableList.every((item) => {
        const { availableStock } = item
        const _availableStock = _.isNumber(availableStock) ? availableStock : 0
        console.log(_availableStock, num)
        if (_availableStock <= num) {
          return false
        }
        return true
      })
      return vaild
    }
  }
}
</script>
